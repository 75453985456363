import {
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
  Cell,
  LabelList,
} from "recharts";

function Graph(datagraph) {
  const data = datagraph.datagraph.datagraph;

  const labelFormatter = (value) => {
    return value.toLocaleString("en");
  };

  return (
    <>
      <ResponsiveContainer aspect={1.6}>
        <BarChart
          width={600}
          height={400}
          data={data}
          margin={{
            top: 15,
            right: 15,
            left: 15,
            bottom: 15,
          }}
        >
          <ReferenceLine y={0} stroke="#C3CFE9" />
          <Bar
            dataKey="amount"
            barSize={43}
            fill="#8884d8"
            label={{ position: "top", formatter: labelFormatter }}
            radius={[10, 10, 0, 0]}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.amount > 0 ? "#3333FF" : "#AAACC5"}
              />
            ))}
            <LabelList dataKey="name" position="bottom" fontSize={13} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default Graph;
