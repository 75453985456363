import React from "react";
import { ReactComponent as Lungs2 } from "../procedure/lungs_1.svg";
import { ReactComponent as Lungs1 } from "../procedure/lungs_2.svg";
import { ReactComponent as Liver1 } from "../procedure/liver_1.svg";
import { ReactComponent as Liver2 } from "../procedure/liver_2.svg";
import { ReactComponent as Kidney1 } from "../procedure/kidney_1.svg";
import { ReactComponent as Kidney2 } from "../procedure/kidney_2.svg";
import { ReactComponent as Open } from "../menu/arrow_down.svg";
import { ReactComponent as Close } from "../menu/arrow_right.svg";

export default function Procedure({
  open,
  setOpen,
  organ,
  setOrgan,
  proce,
  setProce,
}) {
  const changeStatus = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    setOrgan({ ...organ, [name]: value });
  };

  const changeProce = (e) => {
    e.preventDefault();
    let name = e.target.parentNode.name ?? e.target.name;
    let value = e.target.parentNode.value ?? e.target.value;

    setProce({ ...proce, [name]: value });
  };

  return (
    <>
      <div className="flow-root p-1">
        <h3 className="pb-4 float-left">PROCEDURE</h3>
        <p className="float-right ">
          <button onClick={() => setOpen(!open, "pro")}>
            {open ? <Open /> : <Close />}
          </button>
        </p>
      </div>
      {open ? (
        <>
          <div className="grid grid-cols-3 text-center flex place-items-center font-xs mb-10 mt-5 md:mb-5">
            {organ.lung == 0 ? (
              <>
                <div className="md-size-proce">
                  <button name="lung" value="1" onClick={changeStatus}>
                    <Lungs2 style={{ "pointer-events": "none" }} />

                    <h3 className="text-blue">Lung</h3>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="md-size-proce">
                  <button name="lung" value="0" onClick={changeStatus}>
                    <Lungs1 style={{ "pointer-events": "none" }} />
                    <h3 className="text-blue">Lung</h3>
                  </button>
                </div>
              </>
            )}
            {organ.liver == 0 ? (
              <>
                <div className="md-size-proce">
                  <button name="liver" value="1" onClick={changeStatus}>
                    <Liver2 style={{ "pointer-events": "none" }} />
                    <h3 className="text-blue">Liver</h3>
                  </button>{" "}
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="md-size-proce">
                  <button name="liver" value="0" onClick={changeStatus}>
                    <Liver1 style={{ "pointer-events": "none" }} />
                    <h3 className="text-blue">Liver</h3>
                  </button>
                </div>
              </>
            )}
            {organ.kidney == 0 ? (
              <>
                <div className="md-size-proce">
                  <button name="kidney" value="1" onClick={changeStatus}>
                    <Kidney2 style={{ "pointer-events": "none" }} />
                    <h3 className="text-blue">Kidney</h3>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="md-size-proce">
                  <button name="kidney" value="0" onClick={changeStatus}>
                    <Kidney1 style={{ "pointer-events": "none" }} />
                    <h3 className="text-blue">Kidney</h3>
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="mt-5 mb-3 grid grid-rows-2 grid-cols-2 gap-4 content-center">
            {proce.rf == 1 ? (
              <button
                name="rf"
                value="0"
                className="w-[94px] h-[42px] p-[5px] text-center rounded-full bg-blue lg:w-[140px] lg:h-[50px]"
                onClick={changeProce}
              >
                <h3 className="text-white">RF Ablation</h3>
              </button>
            ) : (
              <button
                name="rf"
                value="1"
                className="w-[94px] h-[42px] p-[5px] text-center border-2  border-midle-purple  rounded-full text-midle-purple text-[14px] bg-transparent lg:w-[140px] lg:h-[50px]  "
                onClick={changeProce}
              >
                <h3>RF Ablation</h3>
              </button>
            )}

            {proce.cryo == 1 ? (
              <button
                name="cryo"
                value="0"
                className="w-[94px] h-[42px] p-[5px] text-center rounded-full bg-blue lg:w-[140px] lg:h-[50px]"
                onClick={changeProce}
              >
                <h3 className="text-white">Cryoablation</h3>
              </button>
            ) : (
              <button
                name="cryo"
                value="1"
                className="w-[94px] h-[42px] p-[5px] text-center border-2 border-midle-purple rounded-full text-midle-purple text-[14px] bg-transparent lg:w-[140px] lg:h-[50px]  "
                onClick={changeProce}
              >
                <h3>Cryoablation</h3>
              </button>
            )}

            {proce.mw == 1 ? (
              <button
                name="mw"
                value="0"
                className="w-[94px] h-[42px] p-[5px] text-center rounded-full bg-blue lg:w-[140px] lg:h-[50px]"
                onClick={changeProce}
              >
                <h3 className="text-white">MW Ablation</h3>
              </button>
            ) : (
              <button
                name="mw"
                value="1"
                className=" w-[94px] h-[42px] p-[5px] text-center border-2  border-midle-purple  rounded-full text-midle-purple bg-transparent lg:w-[140px] lg:h-[50px]"
                onClick={changeProce}
              >
                <h3>MW Ablation</h3>
              </button>
            )}

            {proce.biopsy == 1 ? (
              <button
                name="biopsy"
                value="0"
                className="w-[94px] h-[42px] p-[5px] text-center rounded-full bg-blue lg:w-[140px] lg:h-[50px]"
                onClick={changeProce}
              >
                <h3 className="text-white">Biopsy</h3>
              </button>
            ) : (
              <button
                name="biopsy"
                value="1"
                className="w-[94px] h-[42px] py-[5px] text-center border-2  border-midle-purple  rounded-full text-midle-purple text-[14px] bg-transparent lg:w-[140px] lg:h-[50px] "
                onClick={changeProce}
              >
                <h3>Biopsy</h3>
              </button>
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
