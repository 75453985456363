import React, { useState } from "react";
import useCalculations from "../hooks/calculations";
import CashFlow from "./CashFlow";
import HospitalInfo from "./Hospitalnfo";
import Procedure from "./Procedure";
import { ReactComponent as Plus } from "../menu/Group 2.svg";
import Modal from "./Modal";

export default function Basic() {
  const [initval, setInitval] = useState("$175,000");

  const [arrow, setArrow] = useState({
    pro: false,
    hosp: false,
  });
  function setArrow2(value, name) {
    if (arrow.hosp) {
      setArrow({ [name]: value });
    } else {
      setArrow({ [name]: value });
    }
  }

  const [input, setInput] = useState({
    baseCost: 352.2,
    repeatCase: 763.4,
    caseRate: 5.9,
    compCost: 667,
    compRate: 4.9,
    numberCases: 76,
    duration: 28.5,
    baseCost_ab: 5984,
    repeatCase_ab: 5984,
    caseRate_ab: 0,
    compCost_ab: 3263,
    compRate_ab: 3.29,
    numberCases_ab: 39.4286,
    duration_ab: 60,
    interest_rate: 12,
  });

  function update(nextState) {
    setInput(nextState);
  }

  const [organ, setOrgan] = useState({
    lung: 1,
    liver: 1,
    kidney: 1,
  });

  function updateOrgan(nextState) {
    setOrgan(nextState);
  }
  const [proce, setProce] = useState({
    rf: 1,
    cryo: 1,
    mw: 1,
    biopsy: 1,
  });

  function updateProce(nextState) {
    setProce(nextState);
  }

  const { data } = useCalculations(
    initval.replace("$", ""),
    organ,
    proce,
    input
  );

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <div>
        {modal && (
          <Modal openModal={openModal} hubspot={false} literature={true} />
        )}
        <div className="w-fit flex flex-col mt-20 w-full justify-center items-center">
          <h1 className="text-blue font-sb text-[32px] mb-[20px] lg:text-[50px]">
            ROI Calculator
          </h1>
          <div className="flex">
            {/* {FIRST SECTION} */}
            <div className="flex flex-col mr-[26px] w-[175px] lg:w-[260px] lg:mr-[20px]">
              <div className="flex flex-col items-center rounded-2xl bg-blue shadow-lg p-4 h-[176px] border lg:h-[262px]">
                <select
                  className="block rounded-3xl py-2 px-5 w-full text-white border-white bg-blue border-2 text-center text-[12px] font-sb focus-visible:ring-0 focus:border-red lg:text-[16px]"
                  onChange={(e) => setInitval(e.target.value)}
                >
                  <option value={"$175,000"}>MICROMATE NAVI+</option>
                  <option value={"$100,000"}>MICROMATE</option>
                </select>
                <h5 className="text-white mt-6 font-sb text-[32px] lg:text-[50px] lg:mt-[40px]">
                  {initval.toLocaleString("en")}
                </h5>
              </div>
              <div className="flex flex-col items-center rounded-2xl p-4 border mt-6 bg-color-components h-[149px] shadow-lg lg:h-[222px] lg:mt-[40px]">
                <h3 className="border-b-2 border-light-purple text-center text-xs pb-1 lg:text-[16px]">
                  COST PER PROCEDURE
                </h3>
                <h5 className="font-sb text-dark-blue text-[32px] mt-4 lg:text-[50px] lg:mt-[40px]">
                  $60
                </h5>
              </div>
            </div>
            {/* SECOND SECTION - GRAPH */}
            {data && <CashFlow datagraph={data} />}
            {/* PROCEDURE */}
            <div className="flex flex-col w-[236px] lg:w-[350px]">
              <div className="flex flex-col rounded-2xl bg-color-components shadow-lg p-4 pb-1 border">
                <Procedure
                  open={arrow.pro}
                  setOpen={setArrow2}
                  organ={organ}
                  setOrgan={updateOrgan}
                  proce={proce}
                  setProce={updateProce}
                />
              </div>

              <div className="flex flex-col rounded-2xl bg-color-components shadow-lg p-4 pb-1 border mt-6">
                <HospitalInfo
                  open={arrow.hosp}
                  setOpen={setArrow2}
                  input={input}
                  setInput={update}
                />
              </div>
              <div className="flex px-4 mt-[31px] lg-size-plus">
                <h3 className="text-midle-gray font-sr">
                  This calculator information is based on the available
                  literature
                </h3>
                <Plus className="cursor-pointer" onClick={openModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
