import React from "react";
import HubspotForm from "react-hubspot-form";

import CloseIcon from "@mui/icons-material/Close";

function Modal({ openModal, hubspot, literature }) {
  return (
    <>
      <div className="modal_bg">
        <div className="modal-overlay">
          {hubspot && (
            <div
              className="modal-hubspot animated fadeIn faster"
              role="dialog"
              aria-modal="true"
            >
              <div className="flex w-full justify-end">
                <CloseIcon
                  className="cursor-pointer"
                  onClick={() => openModal()}
                />
              </div>
              <HubspotForm
                region="eu1"
                portalId="25855581"
                formId="870c849d-6131-4258-aa8d-6ca642638d55"
                onSubmit={() => console.log("Submit!")}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
          )}
          {literature && (
            <>
              <div
                className="modal-pop animated fadeIn faster"
                role="dialog"
                aria-modal="true"
              >
                <div className="flex w-full justify-end">
                  <CloseIcon
                    className="cursor-pointer"
                    onClick={() => openModal()}
                  />
                </div>

                <h1 className="text-blue font-sb text-[27px]">
                  Micromate ROI Calculator
                </h1>
                <h2 className="text-dark-blue font-sb text-[18px] mb-8">
                  References
                </h2>
                <h3>
                  The following publications have been used to derive costs (of
                  first and repeated procedures) and complication rates for
                  biopsies and ablations of different types, in the lung,
                  kidney, and liver.
                </h3>
                <ul>
                  <li>
                    Chiu, Y. W., Kao, Y. H., Simoff, M. J., Ost, D. E., Wagner,
                    O., Lavin, J., Culbertson, R. A., & Smith, D. G. (2021).
                    Costs of Biopsy and Complications in Patients with Lung
                    Cancer. ClinicoEconomics and outcomes research : CEOR, 13,
                    191–200.{" "}
                    <a href="https://doi.org/10.2147/CEOR.S295494">
                      https://doi.org/10.2147/CEOR.S295494
                    </a>
                  </li>
                  <li>
                    RF Shetty, S. K., Rosen, M. P., Raptopoulos, V., & Goldberg,
                    S. N. (2001). Cost-effectiveness of Percutaneous
                    Radiofrequency Ablation for Malignant Hepatic Neoplasms.
                    Journal of Vascular and Interventional Radiology, 12(7),
                    823–833.{" "}
                    <a href="https://doi.org/10.1016/S1051-0443(07)61507-3">
                      https://doi.org/10.1016/S1051-0443(07)61507-3
                    </a>{" "}
                  </li>
                  <li>
                    Increasing Use of Percutaneous Liver Biopsy Accompanied by
                    Growing Costs (2016)
                    <a href="https://www.hmpgloballearningnetwork.com/site/jcp/article/increasing-use-percutaneous-liver-biopsy-accompanied-growing-costs">
                      https://www.hmpgloballearningnetwork.com/site/jcp/article/increasing-use-percutaneous-liver-biopsy-accompanied-growing-costs
                    </a>
                  </li>
                  <li>
                    Maripuri S, Penson D, F, Ikizler T, A, Cavanaugh K, L:
                    Outpatient versus Inpatient Observation after Percutaneous
                    Native Kidney Biopsy: A Cost Minimization Study. Am J
                    Nephrol 2011;34:64-70.
                    <a href="https://doi.org/10.1159/000328901">
                      https://doi.org/10.1159/000328901
                    </a>{" "}
                  </li>
                  <li>
                    Using Time-Driven Activity Based Costing (TDABC) to
                    Characterize Cost Variability in Interventional Radiology
                    Procedures (2016)
                    <a href="https://archive.rsna.org/2016/16013781.html">
                      https://archive.rsna.org/2016/16013781.html
                    </a>
                  </li>
                  <li>
                    Chiu, Y. W., Kao, Y. H., Simoff, M. J., Ost, D. E., Wagner,
                    O., Lavin, J., Culbertson, R. A., & Smith, D. G. (2021).
                    Costs of Biopsy and Complications in Patients with Lung
                    Cancer. ClinicoEconomics and outcomes research : CEOR, 13,
                    191–200.
                    <a href="https://doi.org/10.2147/CEOR.S295494">
                      https://doi.org/10.2147/CEOR.S295494
                    </a>
                  </li>
                  <li>
                    Short, Scott S.; Papillon, Stephanie; Hunter, Catherine J.;
                    Stanley, Philip; Kerkar, Nanda; Wang, Larry; Azen, Colleen;
                    Wang, Kasper (2013) Percutaneous Liver Biopsy, Journal of
                    Pediatric Gastroenterology and Nutrition: Volume 57 - Issue
                    5 - p 644-648.
                    <a href="https://doi.org/10.1097/MPG.0b013e3182a0e0d8">
                      https://doi.org/10.1097/MPG.0b013e3182a0e0d8
                    </a>{" "}
                  </li>
                  <li>
                    Lin S-Y, Chang CY-Y, Lin C-C, Hsu W-H, Liu I-W, Lin C-D, Kao
                    C-H (2021) Complications of Outpatient and Inpatient Renal
                    Biopsy: A Systematic Review and Meta-Analysis. Diagnostics:
                    11(4):651.
                    <a href="https://doi.org/10.3390/diagnostics11040651">
                      https://doi.org/10.3390/diagnostics11040651
                    </a>{" "}
                  </li>
                  <li>
                    Yeaman, Clinton, et al. “Perioperative Cost Comparison
                    between Percutaneous Microwave Ablation and Partial
                    Nephrectomy for Localized Renal Masses.” (2021) Urology
                    Practice, vol. 8, no. 6, pp. 630–35. Crossref,
                    <a href="https://doi.org/10.1097/upj.0000000000000251">
                      https://doi.org/10.1097/upj.0000000000000251
                    </a>
                  </li>
                  <li>
                    Myers, R. P., Fong, A., & Shaheen, A. A. M. (2008).
                    Utilization rates, complications and costs of percutaneous
                    liver biopsy: a population-based study including 4275
                    biopsies. Liver International, 28(5), 705–712.
                    <a href="https://doi.org/10.1111/j.1478-3231.2008.01691.x">
                      https://doi.org/10.1111/j.1478-3231.2008.01691.x
                    </a>
                  </li>
                  <li>
                    Bertot, L. C., Sato, M., Tateishi, R., Yoshida, H., & Koike,
                    K. (2011). Mortality and complication rates of percutaneous
                    ablative techniques for the treatment of liver tumors: a
                    systematic review. European radiology, 21(12), 2584–2596.
                    <a href="https://doi.org/10.1007/s00330-011-2222-3">
                      https://doi.org/10.1007/s00330-011-2222-3
                    </a>
                  </li>
                  <li>
                    Ma, J., Wang, F., Zhang, W., Wang, L., Yang, X., Qian, Y.,
                    Huang, J., Wang, J., & Yang, J. (2019). Percutaneous
                    cryoablation for the treatment of liver cancer at special
                    sites: an assessment of efficacy and safety. Quantitative
                    imaging in medicine and surgery, 9(12), 1948–1957.
                    <a href="https://doi.org/10.21037/qims.2019.11.12">
                      https://doi.org/10.21037/qims.2019.11.12
                    </a>
                  </li>
                </ul>
                <h3 className="py-6">
                  The following publications have been used to establish the
                  state-of-the-art procedure duration and radiation exposure in
                  percutaneous procedures.
                </h3>
                <ul>
                  <li>
                    Braak, S. J., van Melick, H. H., Onaca, M. G., van
                    Heesewijk, J. P., & van Strijen, M. J. (2012). 3D cone-beam
                    CT guidance, a novel technique in renal biopsy--results in
                    41 patients with suspected renal masses. European radiology,
                    22(11), 2547–2552.
                    <a href="https://doi.org/10.1007/s00330-012-2498-y">
                      https://doi.org/10.1007/s00330-012-2498-y
                    </a>{" "}
                  </li>
                  <li>
                    Braak, S. J., van Strijen, M. J., van Leersum, M., van Es,
                    H. W., & van Heesewijk, J. P. (2010). Real-Time 3D
                    fluoroscopy guidance during needle interventions: technique,
                    accuracy, and feasibility. AJR. American journal of
                    roentgenology, 194(5), W445–W451.
                    <a href="https://doi.org/10.2214/AJR.09.3647">
                      https://doi.org/10.2214/AJR.09.3647
                    </a>
                  </li>
                </ul>
                <h3 className="py-6">
                  The following publications have been used to establish the
                  reimbursement profile for different IR/IO procedures.
                </h3>
                <ul>
                  <li>
                    2020 Reimbursement Guide. Medicare National Average
                    Outpatient Hospital, Ambulatory Surgical Center (ASC) and
                    Physician Codes and Payment for Microwave Ablation (2020)
                    <a href="https://www.jnjmedtech.com/sites/default/files/2020-12/128248-191122_ReimburseGuide_LowRes.pdf">
                      https://www.jnjmedtech.com/sites/default/files/2020-12/128248-191122_ReimburseGuide_LowRes.pdf
                    </a>
                  </li>
                  <li>
                    IO Ablation 2020 2020 Reimbursement Guide. (2020)
                    <a href="https://www.bostonscientific.com/content/dam/bostonscientific/Reimbursement/peripheral-intervention/pdf/IO-ablation-procedure-coding-and-reimbursement-guide.pdf">
                      https://www.bostonscientific.com/content/dam/bostonscientific/Reimbursement/peripheral-intervention/pdf/IO-ablation-procedure-coding-and-reimbursement-guide.pdf
                    </a>
                  </li>
                  <li>
                    2022 NanoKnife CPT Reimbursement Guide (2021)
                    <a href="https://www.angiodynamics.com/wp-content/uploads/2022/01/2022-NanoKnife-CPT-Billing-Codes-Reimbursement-Guide-US-ON-MS-187-Rev07v7.2.pdf">
                      https://www.angiodynamics.com/wp-content/uploads/2022/01/2022-NanoKnife-CPT-Billing-Codes-Reimbursement-Guide-US-ON-MS-187-Rev07v7.2.pdf
                    </a>
                  </li>
                  <li>
                    Reimbursement Guide for BIOSENTRY TRACT SEALANT SYSTEM
                    (2022)
                    <a href="https://www.angiodynamics.com/wp-content/uploads/2022/01/2022-BioSentry-Reimbursement-Guide-USONMS273Rev06v6.0.pdf">
                      https://www.angiodynamics.com/wp-content/uploads/2022/01/2022-BioSentry-Reimbursement-Guide-USONMS273Rev06v6.0.pdf
                    </a>
                  </li>
                </ul>
                <h3 className="py-6">
                  The following publications have been used to establish the
                  diagnostic yield, sensitivity, and sensibility of biopsies
                  performed using the core technology of Micromate™, as well as
                  to define the decrease in complication rate when compared to
                  freehand approaches.
                </h3>
                <ul>
                  <li>
                    Minchev, G., Kronreif, G., Ptacek, W., Dorfer, C., Micko,
                    A., Maschke, S., Legnani, F. G., Widhalm, G., Knosp, E., &
                    Wolfsberger, S. (2019). A novel robot-guided minimally
                    invasive technique for brain tumor biopsies. Journal of
                    neurosurgery, 1–9. Advance online publication.
                    <a href="https://doi.org/10.3171/2018.8.JNS182096">
                      https://doi.org/10.3171/2018.8.JNS182096
                    </a>
                  </li>
                  <li>
                    Minchev, G., Kronreif, G., Martínez-Moreno, M., Dorfer, C.,
                    Micko, A., Mert, A., Kiesel, B., Widhalm, G., Knosp, E., &
                    Wolfsberger, S. (2017). A novel miniature robotic guidance
                    device for stereotactic neurosurgical interventions:
                    preliminary experience with the iSYS1 robot. Journal of
                    neurosurgery, 126(3), 985–996.
                    <a href="https://doi.org/10.3171/2016.1.JNS152005">
                      https://doi.org/10.3171/2016.1.JNS152005
                    </a>
                  </li>
                  <li>
                    Legnani, F. G., Franzini, A., Mattei, L., Saladino, A.,
                    Casali, C., Prada, F., Perin, A., Cojazzi, V., Saini, M.,
                    Kronreif, G., Wolfsberger, S., & DiMeco, F. (2019).
                    Image-Guided Biopsy of Intracranial Lesions with a Small
                    Robotic Device (iSYS1): A Prospective, Exploratory Pilot
                    Study. Operative neurosurgery (Hagerstown, Md.), 17(4),
                    403–412.
                    <a href="https://doi.org/10.1093/ons/opy411">
                      https://doi.org/10.1093/ons/opy411
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Modal;
