import { useMemo } from "react";
const { irr } = require("node-irr");

const useCalculations = (input, organ, treatment, hospital_data) => {
  // Values to Change
  let matrix = [];
  const micromate = 60;
  //const initial_cost = -parseInt(input.replaceAll(",", ""));
  const initial_cost = -parseFloat(input.replace(/[,]/g, ""));

  //Costs for procedures
  const cost_ablation = [
    [3242, 4321.98, 2305],
    [3424, 3424, 4119],
    [4012, 4012, 2139],
  ];

  const cost_biopsy = [1028, 1176, 1211.8];

  //Reimbursements
  const reim_ablation = [
    [4833.17, 4833.17, 4833.17],
    [4833.17, 4833.17, 8413.11],
    [5060.44, 5060.44, 5060.44],
  ];

  const reim_biopsy = [1407, 1372.45, 1372.45];

  var organ_array = Object.keys(organ).map(function (key) {
    return organ[key];
  });

  Object.keys(treatment).forEach(function (key) {
    if (treatment[key] == 1) {
      matrix.push(organ_array);
    } else {
      matrix.push([0, 0, 0]);
    }
  });

  function average_val(values, selections, treatment) {
    let sum = 0;
    let count = 0;

    if (treatment === "biopsy") {
      for (var i = 0; i < 3; i++) {
        sum += Number(selections[3][i]) * Number(values[i]);
        count++;
      }
    } else if (treatment === "ablation") {
      for (var j = 0; j < 3; j++) {
        for (var x = 0; x < 3; x++) {
          sum += selections[j][x] * values[j][x];
          count++;
        }
      }
    }
    return (sum / count).toFixed(2);
  }

  const av_reimbursement = {
    biopsy: average_val(reim_biopsy, matrix, "biopsy"),
    ablation: average_val(reim_ablation, matrix, "ablation"),
  };

  const av_cost = {
    biopsy: average_val(cost_biopsy, matrix, "biopsy"),
    ablation: average_val(cost_ablation, matrix, "ablation"),
  };

  const hospital_input = {
    baseline_cost: hospital_data.baseCost,
    base_repeated_case: hospital_data.repeatCase,
    base_cases: (hospital_data.caseRate / 100).toFixed(3),
    compilation_cost: hospital_data.compCost,
    compilation_case: (hospital_data.compRate / 100).toFixed(3),
    num_case: hospital_data.numberCases,
    procedure_duration: hospital_data.duration,
    OR_turnover: 0.8,
    growth: 0.01,
  };

  const hospital_input_ablations = {
    baseline_cost: hospital_data.baseCost_ab,
    base_repeated_case: hospital_data.repeatCase_ab,
    compilation_cost: hospital_data.compCost_ab,
    compilation_case: (hospital_data.compRate_ab / 100).toFixed(3),
    num_case: hospital_data.numberCases_ab,
    procedure_duration: hospital_data.duration_ab,
  };

  const micromate_data = {
    robotic_duration: 22.69643,
    complication_reduction: 0.43,
    diagnostic_accuracy: 0.97,
    radiation_exposure: 0.44,
  };

  const data = [{ name: "Purchase", amount: initial_cost, NPV: "0", IRR: "0" }];

  let cash_flow = 0;
  const npv_cashflow = [];

  for (let year = 1; year <= 7; year++) {
    // Biopsies
    // console.log("------------", "Biopsies", "----------------");
    let additional_biopsies =
      (hospital_input.OR_turnover *
        hospital_input.num_case *
        hospital_input.procedure_duration *
        (1 -
          micromate_data.robotic_duration /
            hospital_input.procedure_duration)) /
      micromate_data.robotic_duration;
    //console.log("additional_biopsies", additional_biopsies);

    let cost_saving_OR =
      (hospital_input.procedure_duration - micromate_data.robotic_duration) *
      hospital_input.baseline_cost *
      (hospital_input.num_case / 30);
    //console.log("cost_saving_OR",cost_saving_OR)

    let cost_saving_complications =
      hospital_input.compilation_case *
      micromate_data.complication_reduction *
      hospital_input.compilation_cost *
      hospital_input.num_case;
    //console.log("cost_saving_complications",cost_saving_complications)

    let total_cost_savings =
      (hospital_input.base_cases * hospital_input.num_case -
        (1 - micromate_data.diagnostic_accuracy) * hospital_input.num_case) *
      hospital_input.base_repeated_case;
    //console.log("total_cost_savings",total_cost_savings)

    let increased_revenue = additional_biopsies * av_reimbursement.biopsy;
    //console.log("increased_revenue",increased_revenue)

    let total_cost_disposables =
      micromate * (hospital_input.num_case + additional_biopsies);
    //console.log("total_cost_disposables",total_cost_disposables)

    let increase_cost_productivity =
      additional_biopsies * av_cost.biopsy +
      (1 - micromate_data.complication_reduction) *
        hospital_input.compilation_cost *
        hospital_input.compilation_case *
        additional_biopsies +
      (1 - micromate_data.diagnostic_accuracy) *
        hospital_input.base_cases *
        additional_biopsies *
        hospital_input.base_repeated_case;
    //console.log("increase_cost_productivity",increase_cost_productivity)

    // Ablations
    //console.log("------------", "Ablations", "----------------")

    let additional_ablations =
      (hospital_input.OR_turnover *
        (hospital_input_ablations.num_case *
          hospital_input.procedure_duration *
          (1 -
            micromate_data.robotic_duration /
              hospital_input.procedure_duration))) /
      hospital_input_ablations.procedure_duration;
    //console.log("additional_ablations",additional_ablations)

    let cost_saving_OR_ablations = 0;
    cost_saving_OR_ablations =
      ((hospital_input.procedure_duration - micromate_data.robotic_duration) *
        hospital_input_ablations.baseline_cost *
        hospital_input_ablations.num_case) /
      60;
    // console.log("cost_saving_OR_ablations", cost_saving_OR_ablations);
    // console.log(
    //   hospital_input.procedure_duration,
    //   micromate_data.robotic_duration,
    //   hospital_input_ablations.baseline_cost,
    //   hospital_input_ablations.num_case
    // );

    let cost_saving_complications_ablations =
      hospital_input_ablations.compilation_case *
      micromate_data.complication_reduction *
      hospital_input_ablations.compilation_cost *
      hospital_input_ablations.num_case;
    //console.log("cost_saving_complications_ablations",cost_saving_complications_ablations)

    let increased_revenue_ablations =
      additional_ablations * av_reimbursement.ablation;
    //console.log("increased_revenue_ablations",increased_revenue_ablations)

    let total_cost_disposables_ablations =
      (hospital_input_ablations.num_case + additional_ablations) * micromate;
    //console.log("total_cost_disposables_ablations",total_cost_disposables_ablations)

    let increase_cost_productivity_ablations =
      additional_ablations * av_cost.ablation +
      (1 - micromate_data.complication_reduction) *
        hospital_input_ablations.compilation_cost *
        hospital_input_ablations.compilation_case *
        additional_ablations;
    //console.log("increase_cost_productivity_ablations",increase_cost_productivity_ablations)

    let total_savings =
      cost_saving_OR +
      cost_saving_complications +
      total_cost_savings +
      increased_revenue +
      (cost_saving_OR_ablations +
        cost_saving_complications_ablations +
        increased_revenue_ablations);
    //console.log("total_savings",total_savings)

    let total_costs =
      total_cost_disposables +
      increase_cost_productivity +
      (total_cost_disposables_ablations + increase_cost_productivity_ablations);
    //console.log("total_costs",total_costs)

    if (year === 1) {
      cash_flow = initial_cost + total_savings - total_costs;
    } else {
      cash_flow = cash_flow + total_savings - total_costs;
    }

    npv_cashflow.push(cash_flow);

    hospital_input.num_case =
      hospital_input.num_case * (1 + hospital_input.growth);
    hospital_input_ablations.num_case =
      hospital_input_ablations.num_case * (1 + hospital_input.growth);

    // UPDATE THE Graph
    data.push({ name: "Year " + year, amount: parseInt(cash_flow) });
  }

  // const test = [-86258, 3371, 93896, 185327, 277672, 370941, 465142];

  function getNPV(rate, initialCost, cashFlows) {
    return cashFlows.reduce(
      (accumulator, currentValue, index) =>
        accumulator + currentValue / Math.pow(rate / 100 + 1, index + 1),
      initialCost
    );
  }

  var initialCost = 0;
  data[0]["NPV"] = getNPV(
    hospital_data.interest_rate,
    initialCost,
    npv_cashflow
  ).toFixed(2);
  data[0]["IRR"] = irr(npv_cashflow).toFixed(2) * 100;

  return useMemo(
    () => ({
      data,
    }),
    [data]
  );
};

export default useCalculations;
