import Basic from "./components/Basic";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Basic />
    // <BrowserRouter>
    //     <div className="App">
    //       <Routes>
    //         <Route path="/" element={<Basic/>}/>
    //       </Routes>
    //     </div>
    // </BrowserRouter>
  );
}

export default App;
