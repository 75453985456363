import React from "react";
import { useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import { ReactComponent as Open } from "../menu/arrow_down.svg";
import { ReactComponent as Close } from "../menu/arrow_right.svg";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import { Tabs } from "@mui/material";

export default function Basic({ open, setOpen, input, setInput }) {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const { register, handleSubmit } = useForm({
    defaultValues: input,
  });

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = (data) => {
    Object.keys(data).forEach((key) => {
      data[key] = parseFloat(data[key]);
    });
    setInput(data);
  };
  const onError = (errors, e) => console.log(errors, e);

  return (
    <>
      <div className="flow-root p-1">
        <h3 className="pb-4 float-left">HOSPITAL INFO</h3>
        <p className="float-right ">
          <button onClick={() => setOpen(!open, "hosp")}>
            {open ? <Open /> : <Close />}
          </button>
        </p>
      </div>

      {open ? (
        <>
          {" "}
          <Box sx={{ width: "100%" }}>
            <div className="md-size-tab">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Biopsy" {...a11yProps(0)} />
                  <Tab label="Ablation" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              encType="multipart/form-data"
            >
              <TabPanel value={value} index={0}>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Baseline Cost ($)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px] "
                    name={"baseCost"}
                    decimalSeparator="."
                    groupSeparator=","
                    {...register("baseCost")}
                  />
                </div>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Repeated Case Base Cost ($)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name={"repeatCase"}
                    {...register("repeatCase")}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>

                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Repeated Cases Rate (%)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name={"caseRate"}
                    {...register("caseRate")}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>

                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Complication Cost ($)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name={"compCost"}
                    {...register("compCost")}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>

                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Complication Rate (%)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name={"compRate"}
                    {...register("compRate")}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>

                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Number of Cases (per year)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name={"numberCases"}
                    {...register("numberCases")}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Procedure Duration (min)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name={"duration"}
                    {...register("duration")}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Interest Rate (%)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    {...register("interest_rate")}
                    name={"interest_rate"}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Baseline Cost ($)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2 border-midle-gray rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name={"baseCost_ab"}
                    decimalSeparator="."
                    {...register("baseCost_ab")}
                    groupSeparator=","
                  />
                </div>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue text-[10px] lg:text-[16px]">
                    Repeated Case Base Cost ($)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    {...register("repeatCase_ab")}
                    name={"repeatCase_ab"}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue text-[10px] lg:text-[16px]">
                    Repeated Cases Rate (%)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    {...register("caseRate_ab")}
                    name={"caseRate_ab"}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>

                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Complication Cost ($)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    {...register("compCost_ab")}
                    name={"compCost_ab"}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>

                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Complication Rate (%)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    {...register("compRate_ab")}
                    name={"compRate_ab"}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>

                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue  text-[10px] lg:text-[16px]">
                    Av. Number of Cases (per year)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    {...register("numberCases_ab")}
                    name={"numberCases_ab"}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2 ">
                  <div className="col-span-3 font-sr text-dark-blue text-[10px] lg:text-[16px] ">
                    Av. Procedure Duration (min)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    name="duration_ab"
                    {...register("duration_ab")}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>
                <div className="grid grid-flow-row-dense grid-cols-5 mb-2">
                  <div className="col-span-3 font-sr text-dark-blue text-[10px] lg:text-[16px]">
                    Interest Rate (%)
                  </div>
                  <CurrencyInput
                    className="col-span-2 float-right text-center border-2  border-midle-gray  rounded-full text-blue font-medium bg-transparent w-[78px] h-[30px] text-[10px] lg:w-[125px] lg:h-[36px] lg:text-[16px]"
                    {...register("interest_rate")}
                    name={"interest_rate"}
                    decimalSeparator="."
                    groupSeparator=","
                  />
                </div>
              </TabPanel>
              <button
                type="submit"
                className="w-[150px] h-[42px] text-[12px] bg-blue text-white rounded-3xl mt-2 translate-y-[60%] lg:text-[16px]"
              >
                CALCULATE
              </button>
            </form>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
}
