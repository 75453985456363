import React, { useState } from "react";
import Graph from "./Graph";
import Modal from "./Modal";

export default function CashFlow(datagraph) {
  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(!modal);
  };
  return (
    <>
      {modal && (
        <Modal openModal={openModal} hubspot={true} literature={false} />
      )}
      <div className="flex flex-col mr-[26px] lg:mr-[20px]">
        <div className="flex flex-col rounded-2xl justify-between bg-color-components shadow-lg p-4 border w-[438px] h-[351px] lg:w-[555px] lg:h-[524px] ">
          <h3 className="pb-4 text-[12px] lg:text-[16px] ">CASH FLOW ($)</h3>
          <div className="md-size-graph">
            <Graph datagraph={datagraph} />
          </div>
          <div className="flex justify-between translate-y-[5%] lg:translate-y-[35%]">
            <div className="flex">
              <h3>NPV($):&nbsp;</h3>
              <p className="font-sr text-dark-blue text-[12px] lg:text-[16px]">
                {datagraph.datagraph[0].NPV}
              </p>
            </div>
            <div className="flex">
              <h3>IRR:&nbsp;</h3>
              <p className="font-sr text-dark-blue text-[12px] lg:text-[16px]">
                {datagraph.datagraph[0].IRR}%
              </p>
            </div>

            <div className="flex flex-col items-center">
              <p className=" font-sr text-dark-blue text-[12px] lg:text-[16px]">
                For a personalized proposal
              </p>
              <button
                className="bg-dark-blue text-white rounded-3xl mt-2 w-[150px] h-[42px] text-[12px] lg:text-[16px]"
                onClick={openModal}
              >
                CONTACT US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
